<template>
    <div style="height: inherit">
      <section >
        <b-card
        >
        <b-col md="12">
                <b-alert
                variant="info"
                show
                >
                  <h2 class="alert-body">
                    {{ $t("studyiniraq") }}
                    
                  </h2>
                </b-alert>
              </b-col>
              <!-- <b-col md="12" v-if="!isLoged">
                <b-alert
                variant="warning"
                show
                >
                  <h4 class="alert-body">
                    {{ $t("checklogin") }}
                    <span> <b-link
                  :to="{name:'auth-login'}"
                  >{{ $t("reg") }}</b-link></span>
                  </h4>
                  
                </b-alert>
              </b-col> -->
        </b-card>
        </section>
      <section >
        <b-card
        >
        <b-form novalidate class="needs-validation">
              <b-form-row>
                <b-col md="12">
                <b-alert
                variant="info"
                show
                >
                  <h4 class="alert-body">
                    {{ $t("Details of the study you wish to apply") }}
                    
                  </h4>
                </b-alert>
              </b-col>
                <b-col md="4" v-if="$i18n.locale=='ar'">
                  <b-form-group>
                    <label for="input-valid1">{{ $t("The certificate you would like to obtain")}} :</label>
                    <v-select
                      v-model="cert_ar_title"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="certifacats"
                      placeholder=""
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4" v-else>
                  <b-form-group>
                    <label for="input-valid1">{{ $t("The certificate you would like to obtain")}}:</label>
                    <v-select
                      v-model="cert_en_title"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="certifacats"
                      placeholder=""
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label for="input-valid1"> {{ $t("department")}}    <code>{{ $t("required")}}</code> :</label>
                    <v-select
                      v-model="sform.department_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="departments"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
                
                <b-col md="12">
                <b-alert
                variant="info"
                show
                >
                  <h4 class="alert-body">
                   {{ $t("personal information")}}
                  </h4>
                </b-alert>
              </b-col>
                <b-col md="4">
                  <label for="f_name"> {{ $t("name")}}    <code>{{ $t("required")}}</code> :</label>
                  <b-form-input
                    id="f_name"
                    v-model="sform.f_name"
                    placeholder=""
                  />
                </b-col>
                <b-col md="4">
                  <label for="f_m_name"> {{ $t("Mother's name")}}     <code>{{ $t("required")}}</code> :</label>
                  <b-form-input
                    id="f_m_name"
                    v-model="sform.f_m_name"
                    placeholder=""
                  />
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label for="basic-password"> {{ $t("gender")}}     <code>{{ $t("required")}}</code> : </label>
                    <v-select
                      v-model="sform.gender"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label=""
                      :options="genders"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label for="input-valid1"> {{ $t("Nationality")}}     <code>{{ $t("required")}}</code> :</label>
                    <v-select
                      v-model="sform.country_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="nationality"
                      :options="countries"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <label for="input-valid1"> {{ $t("birthday date")}}     <code>{{ $t("required")}}</code> :</label>
                  <cleave
                    id="date"
                    v-model="sform.birthday"
                    class="form-control"
                    :raw="false"
                    :options="date"
                    placeholder="YYYY-MM-DD"
                  />
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label for="input-valid1"> {{ $t("status")}}    <code>{{ $t("required")}}</code> :</label>
                    <v-select
                      v-model="sform.status_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="p_status"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <label for="input-valid1"> {{ $t("Passport No.")}}     <code>{{ $t("required")}}</code> :</label>
                  <b-form-input
                    id="input-valid1"
                    v-model="sform.passport_no"
                    placeholder=""
                  />
                </b-col>
                <b-col md="4">
                  <label for="input-valid1"> {{ $t("Passport validity date")}}     <code>{{ $t("required")}}</code> :</label>
                  <cleave
                    id="date"
                    v-model="sform.passport_expired"
                    class="form-control"
                    :raw="false"
                    :options="date"
                    placeholder="YYYY-MM-DD"
                  />
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label for="input-valid1"> {{ $t("country of residence")}}     <code>{{ $t("required")}}</code> :</label>
                    <v-select
                      v-model="sform.res_country_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="countries"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label for="input-valid1"> {{ $t("Do you have special needs?")}}      <code>{{ $t("required")}}</code> :</label>
                    <v-select
                      v-model="sform.special_needs"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label=""
                      :options="yesORNo"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                <b-alert
                variant="info"
                show
                >
                  <h4 class="alert-body">
                   {{ $t("Latest certificate details")}}
                  </h4>
                </b-alert>
              </b-col>
              <b-col md="4">
                  <b-form-group>
                    <label for="input-valid1"> {{ $t("Certificate obtained")}}      <code>{{ $t("required")}}</code> :</label>
                    <v-select
                      v-model="sform.last_certificate_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="certifacats"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <label for="input-valid1"> {{ $t("Specialization")}}      <code>{{ $t("required")}}</code> :</label>
                  <b-form-input
                    id="input-valid1"
                    v-model="sform.specialization"
                    placeholder=""
                  />
                </b-col>
                <b-col md="4">
                  <label for="input-valid1"> {{ $t("The country of the current certificate")}}      <code>{{ $t("required")}}</code> :</label>
                  <v-select
                      v-model="sform.cert_country_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="countries"
                      placeholder=""
                    />
                </b-col>
                <b-col md="4">
                  <label for="input-valid1"> {{ $t("University awarding the certificate")}}       <code>{{ $t("required")}}</code> :</label>
                  <b-form-input
                    id="input-valid1"
                    v-model="sform.university"
                    placeholder=""
                  />
                </b-col>
                <b-col md="4">
                  <label for="input-valid1"> {{ $t("Year of obtaining the certificate")}}      <code>{{ $t("required")}}</code> :</label>
                  <cleave
                    id="date"
                    v-model="sform.year"
                    class="form-control"
                    :raw="false"
                    :options="date"
                    placeholder="YYYY-MM-DD"
                  />
                </b-col>
                <!-- <b-col md="4">
                  <label for="input-valid1"> {{ $t("The number of published research")}}<code>{{ $t("required")}}</code> :</label>
                  <cleave
                    id="number"
                    v-model="sform.researches"
                    class="form-control"
                    :raw="false"
                    :options="number"
                    placeholder="4"
                  />
                </b-col> -->
                <b-col md="4">
                  <b-form-group>
                    <label for="input-valid1"> {{ $t("English language level")}}      <code>{{ $t("required")}}</code> :</label>
                    <v-select
                      v-model="sform.en_level_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="levels"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label for="basic-password"> {{ $t("Arabic language level")}}       <code>{{ $t("required")}}</code>  :</label>
                    <v-select
                      v-model="sform.ar_level_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="levels"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                <b-alert
                variant="info"
                show
                >
                  <h4 class="alert-body">
                    {{ $t("Contact details")}}
                  </h4>
                </b-alert>
              </b-col>
                <b-col md="4">
                  <label for="input-valid1"> {{ $t("City")}}       <code>{{ $t("required")}}</code> :</label>
                  <b-form-input
                    id="input-valid1"
                    v-model="sform.city"
                    placeholder=""
                  />
                </b-col>
                <b-col md="4">
                  <label for="input-valid1"> {{ $t("Personal email")}}       <code>{{ $t("required")}}</code> :</label>
                  <b-form-input
                    id="input-valid1"
                    v-model="sform.email"
                    placeholder=""
                  />
                </b-col>
                <b-col md="4">
                  <label for="input-valid1"> {{ $t("phone")}}       <code>{{ $t("required")}}</code> :</label>
                 
                  <b-form-input
                    id="input-valid1"
                    v-model="sform.phone"
                    placeholder=""
                  />
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label for="basic-password"> {{ $t("Mobile application")}}       <code>{{ $t("required")}}</code> :</label>
                    <v-select
                      v-model="sform.contact_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label=""
                      :options="apps"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                <b-alert
                variant="info"
                show
                >
                  <h4 class="alert-body">
                   {{ $t("Attachments (PDF)")}}
                  </h4>
                </b-alert>
              </b-col>
              
              <b-col md="4">
                  <label for="input-valid1"> {{ $t("Study documents")}}      <code>{{ $t("required")}}</code> :</label>
                  <b-form-file
                  placeholder="اختر الملف او اسقطها هنا..."
                  drop-placeholder="اختر الملف او اسقطها هنا..."
                    v-model="sform.cert_file"
                    @change="selectFileForpformcertifacates"
                  />
                </b-col>
                <b-col md="4">
                  <label for="input-valid1"> {{ $t("A valid passport for a period of not less than two years from the date of submission")}}       <code>{{ $t("required")}}</code> :</label>
                  <b-form-file
                  placeholder="اختر الملف او اسقطها هنا..."
                  drop-placeholder="اختر الملف او اسقطها هنا..."
                    v-model="sform.passport_file"
                    @change="selectFileForpassport_file"
                  />
                </b-col>
                <!-- <b-col md="4">
                  <label for="input-valid1"> {{ $t("proposal")}}<code>{{ $t("required")}}</code> :</label>
                  <b-form-file
                  placeholder="اختر الملف او اسقطها هنا..."
                  drop-placeholder="اختر الملف او اسقطها هنا..."
                    v-model="sform.proposal_file"
                    @change="selectFileForproposal_file"
                  />
                </b-col> -->
                <b-col md="12">
                <b-alert
                variant="info"
                show
                >
                  <h4 class="alert-body">
                   {{ $t("I'm not a robot")}}
                  </h4>
                </b-alert>
              </b-col>
            <b-col cols="1" v-if="timer.seconds==100">
          <div class="item-options text-center">
          <b-button 
            variant="relief-primary"
            tag="a"
            class="btn-cart"
            style="padding-right:20px;padding-left:20px;padding-top:15px;padding-bottom:15px;"
            @click="sendCode()"
          >
            <feather-icon icon="AlertTriangleIcon" class="mr-50" />
            <span>{{ $t("I'm not a robot")}}</span>
          </b-button>
        </div>
        </b-col>
           
            <b-col md="2" v-if="timer.seconds!=100">
                <b-form-input
                  id="input-valid1"
                  v-model="c"
                  size="lg"
                  placeholder="code"
                />
              </b-col> 
              <b-col cols="2" v-if="timer.seconds!=100 && c.length>0">
          <div class="item-options text-center">
          <b-button
            variant="relief-primary"
            tag="a"
            class="btn-cart"
            style="padding-right:20px;padding-left:20px;padding-top:15px;padding-bottom:15px;"
            @click="checkCode()"
          >
            <feather-icon icon="CheckCircleIcon" class="mr-50" />
            <span>{{ $t("Confirm")}}</span>
          </b-button>
        </div>
        </b-col> 
        <b-col md="1" v-if="timer.seconds<100">
               
               <b-badge
               variant="warning"
               class="badge-glow"
               style="padding-right:20px;padding-left:20px;"
               >
               <h1>{{ timer.seconds }}</h1>

               </b-badge>
            </b-col>
              </b-form-row>
            </b-form>
    </b-card>
    <!-- <b-modal
      ref="modal-success"
        id="modal-success"
        ok-only
        ok-variant="success"
        ok-title="Done"
        modal-class="modal-success"
        centered
        @ok="rSF"
        title=""
      >
        <b-card-text>
          {{$t("requestMSG") }}
        </b-card-text>
      </b-modal> -->
      <b-modal
      id="modal-waiting"
      ok-only
      ok-variant="success"
      ok-title="اغلاق"
      :ok-disabled="precentage !== 0"
      modal-class="modal-success"
      centered
      @ok="submited"
      title="ارسال الطلب"
    >
    <b-card-text v-if="isSubmitted === true &&precentage===0">
        {{ $t("thank you") }} <br> {{$t("isdone")}}<br>{{ $t("willcontact") }}
      </b-card-text>
      <b-card-text v-if="precentage != 0">
        يرجى عدم الخروج قبل تأكيد الارسال 🕵🏻‍♀️
      </b-card-text>
      <b-card-text v-if="isSubmitted==false">
       فشل في ارسال الطلب و ذلك بسبب قطع في الانترنت او عدم ملئ الاستمارة بصورة كاملة
      </b-card-text>
        <b-progress
        v-if="precentage !== 0" :value="precentage"
          max="100"
          precision="2"
          show-value
          :variant="orderstate"
          class="progress-bar-danger"
        />
    </b-modal>
      </section>
    </div>
  </template>
  
  <script>
  import {
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,BForm,
    BRow,BProgress,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,BFormFile,
    BCard,
    BCardBody,BFormGroup,
    BLink,BAlert,
    BImg, BFormTextarea,
    BCardText,BFormRow,
    BButton,BModal,
      VBModal,BBadge,
      BFormValidFeedback,
      BFormInvalidFeedback,
    BPagination,
  } from "bootstrap-vue";
  import { filesUrl } from "@/main.js";import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.us'
  import emailjs from '@emailjs/browser';
  import { SID, TID2, AID } from "@/main.js";
  import Ripple from "vue-ripple-directive";
  import { watch } from "@vue/composition-api";import vSelect from "vue-select";
  import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
  import useAppCustomizer from "@core/layouts/components/app-customizer/useAppCustomizer";
  import ShopLeftFilterSidebar from "./sidebar.vue";import store from '@/store';
  import {
    useShopFiltersSortingAndPagination,
    useShopUi,
    useShopRemoteData,
  } from "./useECommerceShop";
  import { useEcommerceUi } from "./useEcommerce";
  
  export default {
    directives: {
      Ripple,
    },
    components: {
      // BSV
      BDropdown,
      BFormValidFeedback,
      BFormInvalidFeedback,BLink,
      BDropdownItem,
      BFormRadioGroup,BFormFile,
      BFormRadio,BBadge,
      BRow,
      BCol, BFormTextarea,
      BInputGroup,BProgress,
      BInputGroupAppend,
      BFormInput,
      BCard,BFormRow,
      BCardBody,
      BModal,vSelect,BFormGroup,
      VBModal,BForm,Cleave,
      BLink,
      BImg,BAlert,
      BCardText,
      BButton,
      BPagination,
      // SFC
      ShopLeftFilterSidebar,
    },
  
    data() {
      return {
      orderstate:"danger",
      isSubmitted :null,
        cert_ar_title :"بكالوريوس",
        cert_en_title : "Bachelor's",
        precentage:0,
      sortType:"ابحث عن الخدمة المطلوبة ...",
        items: [],
        status:[
          'المرض المفاجئ',
          'وفاة احد الاقرباء من الدرجة الاولى',
          'جادث دهس',
          'التوقيف المفاجئ'
        ],
        yesORNo:[
          'yes',
          'no'
        ],
        totalCount: 0,
        lectures:[],
        levels:[],
        apps:[
          'Whatsapp',
          'Viber',
          'Telegram'
        ],
        department_id: "",
        lectures:[],      
        author_id: "",
        category_id: "",
        departments:[],
        stages:[],
        certifacats:[],
        title: "",
        perPage: 12,
        currentPage: 0,
        types:[],
        countries:[],
        p_status:[],
        genders:[
          'ذكر',
          'انثى'
        ],
        title:"",
        form:{
          title:null,
          depart:null,
          stage:null,
          file:null,
        },
        erform:{
          title:null,
          depart:null,
          depart_id:null,
          stage:null,
          file:null,
        },
        enform:{
          title:null,
          depart:null,
          depart_id:null,
          stage:null,
          stage_id:null,
          type:null,
          type_id:null,
          to:null,
          phone:null,
          file:null,
        },
        cform:{
          title:null,
          depart:null,
          depart_id:null,
          stage:null,
          stage_id:null,
          blood:null,
          type_id:null,
          address:null,
          phone:null,
          file:null,
          payment:null,
        },
        pform:{
          title:null,
          depart:null,
          depart_id:null,
          stage:null,
          stage_id:null,
          type_id:null,
          lecture:null,
          state:null,
        },
        sform:{
          certifacat_id:2,
            department_id:null,
            f_name:null,
            f_m_name:null,
            gender:null,
            country_id:null,
            birthday:null,
            status_id:null,
            passport_no:null,
            passport_expired:null,
            res_country_id:null,
            special_needs:null,
            city:null,
            email:null,
            phone:null,
            contact_type:null,
            last_certificate_id:null,
            specialization:null,
            cert_country_id:null,
            university:null,
            year:null,
            en_level_id:null,
            ar_level_id:null,
            cert_file:null,
            passport_file:null,
},
        isLoged:false,
        date: {
            date: true,
            delimiter: '-',
            datePattern: ['Y', 'm', 'd'],
          },
          number: {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          },
          timer:{
            seconds:100,
            settimer:null
          },
          emailMessage:{
            headerMessage:null,
            bodyMessage:null,
            code:null,
            from_name:null,
            to_email:null,
          },
          c:"",
          code:null,
          toEmail: false,
          toName: false,
          subject: null,
          toID: null,
          m: null,
          toUserID: null,
      };
    },
    setup() {
      const {
      // Layout Type
      layoutType,
        layoutTypeOptions} = useAppCustomizer()
        return {
          // Layout Type
        layoutType,
        layoutTypeOptions,
        }
    },
    created() {
      if (localStorage.getItem("NisourToken")) {
        this.isLoged = true;
      }else{
        this.isLoged = false
      }
    },
    mounted() {
      this.gSM();
      this.gSM7();
      this.gSM8();
      this.gSM9();
      this.gSM10();
    },
    watch: {
      "erform.depart": function (val) {
        this.gSM1();
      },
      "enform.depart": function (val) {
        this.gSM3();
      },
      "cform.depart": function (val) {
        this.gSM4();
      },
      "pform.depart": function (val) {
        this.gSM5();
      },
      "pform.stage": function (val) {
        this.gSM6();
      },
      title: function (val) {
        this.gS();
      },
    precentage: function (val) {
      if(this.precentage<=40){
        this.orderstate = "danger"
      }else if(this.precentage>40&&this.precentage<=70){
        this.orderstate = "warning"
      }else{
        this.orderstate = "success"
      }
    },
      "timer.seconds": function (val) {
        if(val<0){
          this.stopTimer();
        }
        },
        "$i18n.locale": function (val) {
      this.gSM();
      this.gSM7();
      this.gSM8();
      this.gSM9();
      this.gSM10();
      },
    },
    methods: {
      
      checkCode(){
        if(this.c==this.code){
          this.aSM()
        }else{
          this.makeToast(
                "warning",
                "الكود خطأ",
                `الكود المرسل غير متطابق`
              );
              this.stopTimer()
        }
  
      },
      sendCode(){
        if(this.sform.email){
          this.timer.settimer = setInterval(()=>{
           this.timer.seconds--;
        },1000)
        this.code  = null;
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < 10; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      this.code=result
      this.emailMessage.headerMessage ="verification email",
        this.emailMessage.bodyMessage ="Please transfer the code below to complete the form process",
        this.emailMessage.code =this.code ,
        this.emailMessage.from_name ="Al-Nisour University College",
        this.emailMessage.to_email =this.sform.email,
        this.sendEmail(this.emailMessage.headerMessage,this.emailMessage.bodyMessage,this.emailMessage.code,this.emailMessage.from_name,this.emailMessage.to_email,);
  
        }
      },
      sendEmail(h,b,c,f,t) {
        emailjs.send(SID,TID2,{
          headerMessage: h,
          bodyMessage: b, 
          code: c,
          from_name: f,
          to_email:t
          },AID)
          .then((result) => {
              this.makeToast(
                "success",
                "Notification Sender",
                `The notification has been sent successfully`
              );
          }, (error) => {
              console.log('FAILED...', error.text);
          });
      },
      stopTimer(){
        clearInterval(this.timer.settimer)
        this.timer.seconds = 100
        this.code = null
        this.c = null
      },
      async gSM() {
        await this.axios
          .get(
            `since/deparment/clint?skip=0&take=1000&type=5`,{
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((res) => {
            this.departments = [];
            this.departments = res.data.items;
            console.log(res.data.items)
          })
      },
      waiting() {
      this.$bvModal.show('modal-waiting');
    },
      async gSM1() {
        this.stages = [];
        this.erform.stage == "";
        if (isNaN(this.erform.depart)) {
          this.erform.depart_id = this.erform.depart["id"];
        }
        await this.axios
          .get(
            `stage/clint/department/${this.erform.depart_id}?select=true`
          )
          .then((res) => {
            this.stages = [];
            this.stages = res.data.items;
          })
      },
      async gSM2() {
        this.types = [];
        await this.axios
          .get(
            `typesofstudy`,{
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((res) => {
            this.types = [];
            this.types = res.data.items;
          })
      },
      
      async gSM3() {
        this.stages = [];
        this.enform.stage == "";
        if (isNaN(this.enform.depart)) {
          this.enform.depart_id = this.enform.depart["id"];
        }
        await this.axios
          .get(
            `stage/clint/department/${this.enform.depart_id}?select=true`
          )
          .then((res) => {
            this.stages = [];
            this.stages = res.data.items;
          })
      },
      async gSM4() {
        this.stages = [];
        this.cform.stage == "";
        if (isNaN(this.cform.depart)) {
          this.cform.depart_id = this.cform.depart["id"];
        }
        await this.axios
          .get(
            `stage/clint/department/${this.cform.depart_id}?select=true`
          )
          .then((res) => {
            this.stages = [];
            this.stages = res.data.items;
          })
      },
      async gSM5() {
        this.stages = [];
        this.pform.stage == "";
        if (isNaN(this.pform.depart)) {
          this.pform.depart_id = this.pform.depart["id"];
        }
        await this.axios
          .get(
            `stage/clint/department/${this.pform.depart_id}?select=true`
          )
          .then((res) => {
            this.stages = [];
            this.stages = res.data.items;
          })
      },
      async gSM6() {
        this.lectures = [];
        await this.axios
          .get(`lecture?select=true&stage=${this.pform.stage.id}`)
          .then((res) => {
            this.lectures = [];
            this.lectures = res.data.items;
          })
      },
      async gSM7() {
        this.lectures = [];
        await this.axios
          .get(`certificate?take=10&skip=0`,{
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            })
          .then((res) => {
            this.certifacats = [];
            this.certifacats = res.data.items;
          })
      },
      async gSM8() {
        this.lectures = [];
        await this.axios
          .get(`country?take=1000&skip=0`,{
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            })
          .then((res) => {
            this.countries = [];
            this.countries = res.data.items;
          })
      },
      async gSM9() {
        this.lectures = [];
        await this.axios
          .get(`status?take=1000&skip=0`,{
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            })
          .then((res) => {
            this.p_status = [];
            this.p_status = res.data.items;
          })
      },
      async gSM10() {
        this.lectures = [];
        await this.axios
          .get(`levels?take=1000&skip=0`,{
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            })
          .then((res) => {
            this.levels = [];
            this.levels = res.data.items;
          })
      },
      toLogin(){
        this.$router.push("/login");
      },
      selectFileForForm(e) {
        this.erform.file = "";
        this.erform.file = e.target.files[0];
      },
      selectFileForenForm(e) {
        this.enform.file = "";
        this.enform.file = e.target.files[0];
      },
      selectFileForcFormpayment(e) {
        this.cform.payment = "";
        this.cform.payment = e.target.files[0];
      },
      selectFileForcForm(e){
        this.cform.file = "";
        this.cform.file = e.target.files[0];
      },
      selectFileForpformcertifacates(e){
        this.pform.cert_file = "";
        this.pform.cert_file = e.target.files[0];
      },
      selectFileForpassport_file(e){
        this.pform.passport_file = "";
        this.pform.passport_file = e.target.files[0];
      },
      selectFileForenglish_file(e){
        this.pform.english_file = "";
        this.pform.english_file = e.target.files[0];
      },
      selectFileForproposal_file(e){
        this.pform.proposal_file = "";
        this.pform.proposal_file = e.target.files[0];
      },
      selectFileForcv_file(e){
        this.pform.cv_file = "";
        this.pform.cv_file = e.target.files[0];
      },
      showModel(x){
        this.$refs[x].show()
      },
      hideModel(){
        this.erform.title = ""
        this.erform.email = ""
        this.erform.depart = ""
        this.erform.depart_id = ""
        this.erform.file = ""
        this.erform.stage = ""
      },
      showModel(){
        this.$refs["modal-success"].show()
      },
      clicked(){
        useAppCustomizer().layoutType['value']="vertical"
        // store.commit('app-config/UPDATE_CONTENT_WIDTH', 'vertical')
      },
      reClicked(){
        useAppCustomizer().layoutType['value']="horizontal"
        // store.commit('app-config/UPDATE_CONTENT_WIDTH', 'vertical')
      },
      changeType(id){
        this.updateType(id) 
      },
      checkCurrent(){
        setTimeout(() =>this.gS(), 1000);
      },
      async gS() {
        await this.axios
          .get(
            `services?take=${this.perPage}&skip=${this.currentPage - 1}&title=${this.title}`
          )
          .then((res) => {
            this.items = [];
            this.totalCount = "";
            this.totalCount = res.data.totalCount;
            this.items = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      getImage(path) {
        return `${filesUrl}/${path}`;
      },
      
      sendNote(m, n, e) {
          const fromName = localStorage.getItem("NisourUserName");
          const fromEmail = localStorage.getItem("NisourEmail");
          emailjs
            .send(
              SID,
              TID,
              {
                from_name: fromName,
                to_name: n,
                message: m,
                to_email: e,
                reply_to: fromEmail,
              },
              AID
            )
            .then(
              (result) => {
                this.toName = "";
              },
              (error) => {
                console.log("FAILED...", error.text);
              }
            );
        },
      async aNM() {
        let data = new FormData();
        data.append("title", this.erform.title);
        data.append("depart", this.erform.depart.title);
        data.append("stage", this.erform.stage.title);
        data.append("email", this.erform.email);
        data.append("image", this.erform.file);
        await this.axios
          .post(`emails`, data,{
            onUploadProgress: progressEvent => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            }
          })
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              (this.toEmail = 'ahmed.itm@nuc.edu.iq'),
              (this.toName = 'قسم التسجيل'),
              (this.toID = 0),
              (this.toUserID = 211),
              (this.subject ="طلب ايميل جامعي"),
              (this.m = ``);
              this.sendNote(this.m, this.toName, this.toEmail);
  
              this.makeToast(
                "success",
                "تم ارسال الطلب",
                "ستستلم الايميل الجامعي على الايميل المرفق في الطلب خلال يومين"
              );
            }
            this.rF();
            this.precentage=0
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
        this.rF();
      },
      async aNMEN() {
        let data = new FormData();
        data.append("title", this.enform.title);
        data.append("department_id", this.enform.depart_id);
        data.append("stage_id", this.enform.stage.id);
        data.append("type_id", this.enform.type.id);
        data.append("to", this.enform.to);
        data.append("phone", this.enform.phone);
        data.append("image", this.enform.file);
        await this.axios
          .post(`endorsement`, data,{
            onUploadProgress: progressEvent => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            }
          })
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "تم ارسال الطلب",
                "سيتم التواصل معك عن طريق الايميل في حال اكتمال الطلب او وجود مشكلة"
              );
            }
            this.renF();
            this.precentage=0
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
        this.renF();
      },
      async aCM() {
        let data = new FormData();
        data.append("title", this.cform.title);
        data.append("department_id", this.cform.depart_id);
        data.append("stage_id", this.cform.stage.id);
        data.append("type_id", this.cform.type.id);
        data.append("blood", this.cform.blood);
        data.append("address", this.cform.address);
        data.append("phone", this.cform.phone);
        data.append("payment", this.cform.payment);
        data.append("image", this.cform.file);
        await this.axios
          .post(`card`, data,{
            onUploadProgress: progressEvent => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            }
          })
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "تم ارسال الطلب",
                "سيتم التواصل معك عن طريق النظام في حال اكتمال الطلب او وجود مشكلة"
              );
            }
            this.renF();
            this.precentage=0
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
        this.renF();
      },
      async aPM() {
        const status = [];
        if (this.pform.state.length > 0) {
          this.pform.state.map(function (value, key) {
            status.push(value);
          });
        }
        let data = new FormData();
        data.append("title", this.pform.title);
        data.append("department_id", this.pform.depart_id);
        data.append("stage_id", this.pform.stage.id);
        data.append("type_id", this.pform.type.id);
        data.append("lecture_id", this.pform.lecture.id);
        data.append("state", this.pform.state);
        await this.axios
          .post(`postpone`, data,{
            onUploadProgress: progressEvent => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            }
          })
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "تم ارسال الطلب",
                "سيتم التواصل معك عن طريق النظام في حال اكتمال الطلب او وجود مشكلة"
              );
            }
            this.rPF();
            this.precentage=0
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
        this.rPF();
      },
      submited(){
      this.isSubmitted = null
    },
      async aSM() {
        this.waiting()
        let data = new FormData();
        data.append("certificate_id", this.sform.certifacat_id);
        data.append("department_id", this.sform.department_id.id);
        data.append("f_name", this.sform.f_name);
        data.append("f_m_name", this.sform.f_m_name);
        data.append("gender", this.sform.gender);
        data.append("country_id", this.sform.country_id.id);
        data.append("birthday", this.sform.birthday);
        data.append("status_id", this.sform.status_id.id);
        data.append("passport_no", this.sform.passport_no);
        data.append("passport_expired", this.sform.passport_expired);
        data.append("res_country_id", this.sform.res_country_id.id);
        data.append("special_needs", this.sform.special_needs);
        data.append("last_certificate_id", this.sform.last_certificate_id.id);
        data.append("specialization", this.sform.specialization);
        data.append("cert_country_id", this.sform.cert_country_id.id);
        data.append("university", this.sform.university);
        data.append("year", this.sform.year);
        data.append("researches", this.sform.researches);
        data.append("en_level_id", this.sform.en_level_id.id);
        data.append("ar_level_id", this.sform.ar_level_id.id);
        data.append("cert_file", this.sform.cert_file);
        data.append("passport_file", this.sform.passport_file);
        data.append("city", this.sform.city);
        data.append("email", this.sform.email);
        data.append("phone", this.sform.phone);
        data.append("contact_type", this.sform.contact_type);
        await this.axios
          .post(`scholarship`, data,{
            onUploadProgress: progressEvent => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            }
          })
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              if(this.$i18n.locale=='ar'){
                this.makeToast(
                "success",
                "تم ارسال الطلب",
                "سيتم التواصل معك عن طريق الايميل في حال اكتمال الطلب او وجود مشكلة"
              );
            //   this.showModel()
              }else{
                this.makeToast(
                "success",
                "request sent",
                 "You will be contacted via e-mail if the request is complete or there is a problem."
              );
              }
            }
            this.renF();
            this.precentage=0
            this.isSubmitted=true
              this.stopTimer()
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
                this.stopTimer()
          });
        this.renF();
      },
      async sendNoti() {
          await this.axios.post(`notifications`, this.notifi);
        },
      rF(){
        this.erform.title=null,
        this.erform.depart=null,
        this.erform.depart_id=null,
        this.erform.stage=null,
        this.erform.file=null
      },
      renF(){
        this.enform.title=null,
        this.enform.depart=null,
        this.enform.depart_id=null,
        this.enform.stage=null,
        this.enform.stage_id=null,
        this.enform.type=null,
        this.enform.to=null,
        this.enform.phone=null,
        this.enform.file=null
      },
      rCF(){
        this.cform.title=null,
        this.cform.depart=null,
        this.cform.depart_id=null,
        this.cform.stage=null,
        this.cform.stage_id=null,
        this.cform.blood=null,
        this.cform.type=null,
        this.cform.address=null,
        this.cform.phone=null,
        this.cform.payment=null,
        this.cform.file=null
      },
      rPF(){
        this.pform.title=null,
        this.pform.depart=null,
        this.pform.depart_id=null,
        this.pform.stage=null,
        this.pform.stage_id=null,
        this.pform.type=null,
        this.pform.lectures=[],
        this.pform.state=[]
      },
      rSF(){
        this.sform.certifacat_id=2,
        this.sform.department_id=null,
        this.sform.f_name=null,
        this.sform.m_name=null,
        this.sform.g_name=null,
        this.sform.f_m_name=null,
        this.sform.m_m_name=null,
        this.sform.g_m_name=null
        this.sform.gender=null,
        this.sform.country_id=null,
        this.sform.birthday=null,
        this.sform.birthday_country_id=null,
        this.sform.status_id=null,
        this.sform.passport_no=null,
        this.sform.passport_expired=null,
        this.sform.res_country_id=null
        this.sform.city=null,
        this.sform.special_needs=null,
        this.sform.last_certificate_id=null,
        this.sform.specialization=null,
        this.sform.cert_country_id=null,
        this.sform.university=null,
        this.sform.year=null,
        this.sform.researches=null
        this.sform.scopus=null,
        this.sform.orcid=null,
        this.sform.scholar=null,
        this.sform.en_level_id=null,
        this.sform.ar_level_id=null,
        this.sform.cert_file=null,
        this.sform.passport_file=null,
        this.sform.english_file=null
        this.sform.proposal_file=null
        this.sform.address_country_id=null,
        this.sform.city=null,
        this.sform.post_address=null,
        this.sform.street=null,
        this.sform.email=null
        this.sform.phone=null,
        this.sform.contact_type=null,
        this.sform.cert_file=null,
        this.sform.passport_file=null,
        this.sform.english_file=null,
        this.sform.proposal_file=null,
        this.sform.cv_file=null
      },
      errorToast() {
        this.makeToast(
          "danger",
          "update failed",
          "some thing want wrong, i`m sorry :("
        );
      },
      makeToast(variant = null, title = null, body = null) {
        this.$bvToast.toast(body, {
          title: title,
          variant,
          solid: false,
        });
      },
      getImage(path) {
        return `${filesUrl}/${path}`;
      },
    },
    setup() {
      const { filters, filterOptions, sortBy, sortByOptions } =
        useShopFiltersSortingAndPagination();
  
      const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();
  
      const { itemView, itemViewOptions, totalProducts } = useShopUi();
  
      const { products, fetchProducts } = useShopRemoteData();
  
      const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
  
      // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
      const fetchShopProducts = () => {
        fetchProducts({
          q: filters.value.q,
          sortBy: sortBy.value.value,
          page: filters.value.page,
          perPage: filters.value.perPage,
        }).then((response) => {
          products.value = response.data.products;
          totalProducts.value = response.data.total;
        });
      };
  
      fetchShopProducts();
  
      watch(
        [filters, sortBy],
        () => {
          fetchShopProducts();
        },
        {
          deep: true,
        }
      );
  
      return {
        // useShopFiltersSortingAndPagination
        filters,
        filterOptions,
        sortBy,
        sortByOptions,
  
        // useShopUi
        itemView,
        itemViewOptions,
        totalProducts,
        toggleProductInWishlist,
        handleCartActionClick,
  
        // useShopRemoteData
        products,
  
        // mqShallShowLeftSidebar
        mqShallShowLeftSidebar,
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "~@core/scss/base/pages/app-ecommerce.scss";
  </style>
  
  <style lang="scss" scoped>
  .item-view-radio-group ::v-deep {
    .btn {
      display: flex;
      align-items: center;
    }
  }
  .image-book {
    width: 400px;
    height: 300px;
    min-height: 15.85rem;
    }
  </style>
  